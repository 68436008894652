var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('CCard',[_c('CCardHeader',[_c('br'),_c('h5',[_vm._v(" Lista Case Funerarie "),_c('CButton',{staticClass:"button-create-casa-funeraria",attrs:{"size":"md","color":"info"},on:{"click":() => {
                  this.$router.push({ name: 'casaFuneraria' });
                }}},[_c('CIcon',{attrs:{"name":"cil-plus"}}),_vm._v(" Aggiungi Casa Funeraria ")],1)],1)]),_c('CCardBody',[_c('CDataTable',{attrs:{"items":_vm.items,"fields":_vm.fields,"pagination":""},scopedSlots:_vm._u([{key:"bottoneVisualizzazione",fn:function({ item }){return [_c('td',[_c('CButton',{staticClass:"m-1",attrs:{"color":"info"},on:{"click":() => {
                      _vm.$router.push({ name: 'casaFuneraria', params: { id: item.id } });
                    }}},[_vm._v(" Modifica ")]),_c('CButton',{staticClass:"m-1",attrs:{"color":"danger"},on:{"click":() => {
                      _vm.deleteCasaFuneraria(item);
                    }}},[_vm._v(" Elimina ")])],1)]}}])})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }